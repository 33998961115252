define("discourse/plugins/discourse-zendesk-plugin/discourse-zendesk-plugin/templates/connectors/after-topic-footer-buttons/zendesk-topic-actions", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.currentUser.staff}}
    {{#if this.valid_zendesk_credential}}
      {{#if this.zendesk_id}}
        <a
          href={{this.zendesk_url}}
          target="_blank"
          class="btn-primary btn"
          rel="noopener noreferrer"
        >
          <i class="fa fa-clone"></i>
          {{i18n "topic.view_zendesk_issue"}}
        </a>
      {{else}}
        <DButton
          class="btn-primary"
          @action={{action "createZendeskIssue"}}
          @label="topic.create_zendesk_issue"
          @disabled={{this.dirty}}
        />
      {{/if}}
    {{else}}
      <p>{{i18n "zendesk.credentials_not_setup"}}</p>
    {{/if}}
  {{/if}}
  */
  {
    "id": "68W/af0k",
    "block": "[[[41,[30,0,[\"currentUser\",\"staff\"]],[[[41,[30,0,[\"valid_zendesk_credential\"]],[[[41,[30,0,[\"zendesk_id\"]],[[[1,\"      \"],[10,3],[15,6,[30,0,[\"zendesk_url\"]]],[14,\"target\",\"_blank\"],[14,0,\"btn-primary btn\"],[14,\"rel\",\"noopener noreferrer\"],[12],[1,\"\\n        \"],[10,\"i\"],[14,0,\"fa fa-clone\"],[12],[13],[1,\"\\n        \"],[1,[28,[35,1],[\"topic.view_zendesk_issue\"],null]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],[[[1,\"      \"],[8,[39,2],[[24,0,\"btn-primary\"]],[[\"@action\",\"@label\",\"@disabled\"],[[28,[37,3],[[30,0],\"createZendeskIssue\"],null],\"topic.create_zendesk_issue\",[30,0,[\"dirty\"]]]],null],[1,\"\\n\"]],[]]]],[]],[[[1,\"    \"],[10,2],[12],[1,[28,[35,1],[\"zendesk.credentials_not_setup\"],null]],[13],[1,\"\\n\"]],[]]]],[]],null]],[],false,[\"if\",\"i18n\",\"d-button\",\"action\"]]",
    "moduleName": "discourse/plugins/discourse-zendesk-plugin/discourse-zendesk-plugin/templates/connectors/after-topic-footer-buttons/zendesk-topic-actions.hbs",
    "isStrictMode": false
  });
});